import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Menu, MenuOpen } from '@mui/icons-material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useGlobalStore } from '../../global-store'
import { colors, constants } from '../tokens'
import DevUserForm from './dev-user-form'
import VerticalDrawer from './vertical-drawer'

const approvalItems = [
  { role: 'IsPoolCoordinator', label: 'Pool Coordinator', link: '/pool-coordinator' },
  { role: 'IsAuthorizedSigner', label: 'Authorized Signer', link: '/auth-signer-queue' },
  { role: 'IsTicketFulfiller', label: 'Fulfillment', link: '/ticket-fulfiler-queue' },
  { role: 'IsTicketFulfiller', label: 'Order', link: '/order' },
  { role: 'IsTicketFulfiller', label: 'Complete', link: '/complete-queue' },
]

const NestedList = ({ menuLabel, nestedMenuArray, userInfo, children }: any) => {
  const [isOpen, setIsOpen] = useState(false)

  const visibleItems = nestedMenuArray?.filter((item: any) => userInfo?.data?.[item.role])

  // Don't render the main menu if there are no visible sub-menu items
  if (visibleItems?.length === 0) {
    return null
  }

  return (
    <>
      <CustomListItemButton onClick={() => setIsOpen(!isOpen)} divider>
        <ListItemText>
          <Typography variant="h6">{menuLabel}</Typography>
        </ListItemText>
        <div> {isOpen ? <ExpandLess /> : <ExpandMore />}</div>
      </CustomListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children ? (
          children
        ) : (
          <List component="div" disablePadding sx={{ width: '100%' }}>
            {isOpen &&
              visibleItems?.map(
                (item: any) =>
                  userInfo?.data?.[item.role] && (
                    <CustomListItem divider key={item.label}>
                      <Link href={item.link}>
                        <Typography variant="h6" sx={{ paddingLeft: '1rem' }}>
                          {item.label}
                        </Typography>
                      </Link>
                    </CustomListItem>
                  )
              )}
          </List>
        )}
      </Collapse>
    </>
  )
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  backgroundColor: '#122d67',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

export default function GlobalNav({ show = true }) {
  const router = useRouter()
  const { userInfo } = useGlobalStore()
  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box sx={{ height: constants.globalHeaderHeight, display: show ? 'block' : 'none' }}>
      <AppBar
        sx={{
          height: constants.globalHeaderHeight,
          maxHeight: constants.globalHeaderHeight,
          zIndex: 1301,
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            justifyContent: 'space-between',
            height: '100%',
            paddingRight: constants.globalBufferMedium,
            paddingLeft: constants.globalBufferMedium,
            boxShadow: isOpen ? '0' : '3px 3px 12px rgb(0 0 0 / 85%)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {userInfo?.data && (
              <IconButton
                edge="start"
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={toggleDrawer}
              >
                {isOpen ? <MenuOpen fontSize="large" /> : <Menu fontSize="large" />}
              </IconButton>
            )}
            <Typography
              component="h1"
              variant="h5"
              color="inherit"
              sx={{
                paddingLeft: constants.globalBufferSmall,
                fontSize: '20px',
                fontWeight: 900,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'baseline',
              }}
            >
              <HeaderLink
                {...(userInfo?.data ? { href: '/' } : { href: 'javascript:void(0)' })}
                aria-describedby="Requestix Home"
              >
                {router.pathname.includes('admin') ? 'RequesTix ADMIN' : 'RequesTix'}
              </HeaderLink>
            </Typography>
          </div>
          {!!userInfo.data && <DevUserForm />}
        </Toolbar>
      </AppBar>
      <VerticalDrawer isOpen={isOpen} setIsClosed={() => setIsOpen(false)}>
        <List sx={{ width: '100%', paddingTop: 0 }}>
          <CustomListItem divider>
            <Link href="/">
              <Typography variant="h6">Events</Typography>
            </Link>
          </CustomListItem>
          <CustomListItem divider>
            <Link href="/requests">
              <Typography variant="h6">Request Status</Typography>
            </Link>
          </CustomListItem>

          {/* Approval Section */}

          <NestedList menuLabel="Approvals" nestedMenuArray={approvalItems} userInfo={userInfo} />

          {/* Manage Section */}
          <NestedList menuLabel="Manage" userInfo={userInfo}>
            <List component="div" disablePadding sx={{ width: '100%' }}>
              {(userInfo.data?.IsPoolCoordinator || userInfo.data?.IsTicketFulfiller) && (
                <CustomListItem divider>
                  <Link href="/search">
                    <Typography variant="h6" sx={{ paddingLeft: '1rem' }}>
                      Search
                    </Typography>
                  </Link>
                </CustomListItem>
              )}
              <CustomListItem divider>
                <Link href="/events">
                  <Typography variant="h6" sx={{ paddingLeft: '1rem' }}>
                    Events
                  </Typography>
                </Link>
              </CustomListItem>
              {(userInfo.data?.IsPoolCoordinator || userInfo.data?.IsTicketFulfiller) && (
                <CustomListItem divider>
                  <Link href="/admin">
                    <Typography variant="h6" sx={{ paddingLeft: '1rem' }}>
                      Admin
                    </Typography>
                  </Link>
                </CustomListItem>
              )}
              <CustomListItem divider>
                <Link href="/request-management">
                  <Typography variant="h6" sx={{ paddingLeft: '1rem' }}>
                    Request Dashboard
                  </Typography>
                </Link>
              </CustomListItem>

              {!userInfo.isError && (
                <CustomListItem divider>
                  <Link href="/users">
                    <Typography variant="h6" sx={{ paddingLeft: '1rem' }}>
                      {' '}
                      Users
                    </Typography>
                  </Link>
                </CustomListItem>
              )}
              <CustomListItem divider>
                <Link href="/settings">
                  <Typography variant="h6" sx={{ paddingLeft: '1rem' }}>
                    Workflow
                  </Typography>
                </Link>
              </CustomListItem>
            </List>
          </NestedList>
        </List>
      </VerticalDrawer>
    </Box>
  )
}

const HeaderLink = styled(Link)`
  color: white !important;
  text-decoration: none;
  & :visited,
  :active {
    color: white !important;
  }
`

const CustomListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #3e7cde;
  width: 100%;
  min-width: 240px;
  padding: 0;

  a {
    text-decoration: none;
    width: 100%;
    padding: ${constants.globalBufferXS};
    padding-left: 70px;
    // color: ${colors.globalLightBlue};
  }

  a:visited {
    color: inherit;
  }
`

const CustomListItemButton = styled(ListItemButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.globalLink};
  width: 100%;
  min-width: 240px;
  padding: ${constants.globalBufferXS};
  padding-left: 70px;
`
