export const endpoints = {
  rootUri: process.env.NEXT_PUBLIC_API_ROOT_URI,
  getApprovalQueue: '/approvals/auth-signer-requests',
  submitApprovalRequest: '/approvals/process-requests',
  getEvents: '/events',
  search: '/events/drop-down',
  venues: '/venues',
  ccValidation: '/vaults/TokenizeAsync',
  ccTokenexIframe: '/vaults/tokenize',
  getRequests: '/requests/status',
  getRequestDetail: '/requests/details',
  content: '/requests/messages',
  userAllocation: '/requests/calculate-allocations',
  userInfo: '/users/user-info',
  submitTicketRequest: '/requests/submit',
  cancelTicketRequest: '/requests/cancel',
  resendPaymentInfo: '/requests/resend-internal-payment-info',
  sendPaymentInfo: '/guests/payment-info',
  getEventDetail: '/events/id',
  getEmployees: '/users/employees',
  getEmployeeById: '/users/employee/id',
  getApprovalQueueCounts: '/home/get-count-per-queue',
  getApprovalQueueDetail: '/home/get-detail-per-queue',
  getRequestLockedStatus: '/requests/locked-status',
  sendTicketConfirmationEmail: '/requests/ticket-confirmation-async',
  searchDepartment: '/reports/auto-search-department',
  searchDivision: '/reports/auto-search-division',
  ticketRequest: '/reports/tickets-request',
  searchRecipientFirstName: '/reports/auto-search-will-call',
  searchVenue: '/reports/auto-search-venue',
  searchEvent: '/reports/auto-search-event',
  searchEmployee: '/reports/employees',
  searchTicketPools: '/approvals/ticket-pools',
  poolCordinatorRequets: '/approvals/pool-coordinator-requests',
  getTicketPools: '/reports/ticket-pools',
  submitPoolProcessRequest: '/approvals/process-requests',
  resendPaymentLink: `/requests/resend-payment-request`,
  ticketFulfillerRequests: `/approvals/ticket-fulfiller-requests`,
  getEventsList: `/home`,
  updateEvent: `/events`,
  getEventsType: `/events/type`,
  addEventsType: `/events/type`,
  requestRollback: `/requests/rollback`,
  orderQueue: '/approvals/ticket-order-requests',
  updatePricePoint: `/events/price-point-link`,
  getPricePointDetails: `/events/read-price-point-detail`,
  deleteEventType: `/events/type`,
  completeQueueRequests: '/approvals/complete-requests',
  getUsersAdmin: '/users-info/local-users-info',
  credirCardInfo: '/requests/card-info',
  getDivisions: '/divisions',
  updateDivision: '/divisions/update',
  searchUser: '/users-info/local-users-info',
  getDelegatesUsers: '/delegates/all-by-user',
  addNewDelegate: '/delegates/create',
  deleteDelegate: '/delegates/delete',
  createNewUser: '/users-info/add-new-user',
  assignRole: '/groups/users',
  getGroupObjects: '/group-objects/assigned',
  copyProfileFrom: '/users-info/copy-profile-from',
  requestManagement: '/requests',
  requestForEdit: '/requests/request-for-edit',
  submitEditRequest: '/requests',
  workFlowInstance: '/approvals/approval-history',
  findTeams: '/divisions/find-teams',
  getAllocations: '/allocations/',
  getAllocationSeasons: '/allocations/all-seasons',
  updateAllocation: '/allocations/',
  getUserProfiles: '/users-info/profile-detail',
  deleteUserGroup: '/groups/users',
  getEmployeeIdByAdname: '/users-info/employee-id-by-adname',
}

// export const apiKey = 'd25b84731b2a1b1b7744284189389a1a'

// export const apiKeyPs = '5eb2a135ae874470878544106dbf7f8d'

export const apiKey = process.env.NEXT_PUBLIC_API_KEY

export const testval = process.env.NEXT_PUBLIC_TEST_KEY

export const IDLE_TIME = 15 * 60 * 1000

export const PAGE_SIZE = 15
